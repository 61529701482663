import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {
   Button,
   Card,
   CardContent, CircularProgress, DialogTitle, FormHelperText,
   Grid, IconButton, InputAdornment, Menu, MenuItem, TextField, Tooltip,
   Typography
} from "@mui/material";
import {
   Delete,
   FindReplace, Restore,
   Save, ThumbDown, ThumbUp,
} from "@mui/icons-material";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import {useParams} from "react-router-dom";
import { toCapitalizeFromUnderScore} from "../../../lib/helpers/helpers";
import useOrganizationFaqs from "../../../lib/hooks/useOrganizationFaqs";
import {getButtonBorderColor} from "../../../lib/helpers/colorHelpers";
import {makeStyles} from "@mui/styles";
import {DemoContext} from "../../../lib/contexts/DemoContext";
import {postAutoFill} from "../../../lib/api/postAutoFill";
import {deleteOrganizationFaq} from "../../../lib/api";
import DeleteFAQDialog from "../DeleteFAQDialog/DeleteFAQDialog";

const useStyles = makeStyles(theme => ({
   root: {
      //display: 'flex',
   },
   editor:{
      borderStyle:'solid',
      borderWidth:1,
      borderRadius:5,
   }
}));
const SpecificFAQ = ({summary = false, management}) => {
   const classes = useStyles();
   
   const {
      state: {  darkMode, matchSM, faqs, token, userID, isLoadingFAQ, selectedFAQ },
      actions: {  updateFAQ, enqueueSnackbar, refetchFaq },
   } = useContext(DemoContext);
   
   
   const [question, setQuestion] = useState(selectedFAQ?.question || '')
   const [hashtags, setHashtags] = useState(selectedFAQ?.hashtags || '')
   const [input, setInput] = useState(selectedFAQ?.answer || '')
   const [status, setStatus] = useState(selectedFAQ?.status || '');
   const [isLoadingAutoFillHashtags, setIsLoadingAutoFillHashtags] = useState(false);
   const [isLoadingAutoFillQuestion, setIsLoadingAutoFillQuestion] = useState(false);
   const [isLoadingAutoFill, setIsLoadingAutoFill] = useState(false);
   const [showingDelete, setShowingDelete] = useState(false);
   const [anchorEl, setAnchorEl] = useState(null);
   const textareaRef = useRef(null);
   
   useEffect(()=>{
      reset()
      refetchFaq()
   },[selectedFAQ])
   
   const reset = useCallback(() => {
      if(selectedFAQ){
         setQuestion(selectedFAQ.question)
         setHashtags(selectedFAQ.hashtags != null ? selectedFAQ.hashtags : '')
         setInput(selectedFAQ.answer)
         setStatus(selectedFAQ.status)
      }
   },[selectedFAQ]);
   const handleMenuClick = (event) => {
      setAnchorEl(event.currentTarget);
   };
   const handleMenuItemClick = (item) => {
      setStatus(item)
      setAnchorEl(null);
   };
   const handleClose = () => {
      setAnchorEl(null);
      setShowingDelete(false)
   };
   
   const stateMap = {
      hashtags: [setHashtags, setIsLoadingAutoFillHashtags],
      question: [setQuestion, setIsLoadingAutoFillQuestion],
      answer: [setInput, setIsLoadingAutoFill],
   };
   const handleAutofill = useCallback(async (event_type) => {
      const [setStateValue, setIsLoading] = stateMap[event_type] || [setInput, setIsLoadingAutoFill];
      setIsLoading(true)
      try {
         const details = {
            keywords:hashtags,
            question:question,
            answer:input,
         }
         const result = await postAutoFill(token, userID, event_type, details);
         if(result && result.autofill){
            try {
               // Attempt to parse the JSON. If it's not JSON, an error will be thrown
               const parsed = JSON.parse(result.autofill);
               setStateValue(parsed);
            } catch (error) {
               // If it's a regular string, just set the state to the string itself
               setStateValue(result.autofill);
            }
         }else{
            enqueueSnackbar('Something went wrong!', {variant:'info'})
         }
      } catch (error) {
         console.error(error);
      } finally {
         setIsLoading(false);
      }
   }, [token, userID, hashtags, question, input, setHashtags, setQuestion, setInput]);
   
   const handleSave = useCallback(() => {
      updateFAQ(selectedFAQ.id, {
         question: question,
         answer: input,
         hashtags: typeof hashtags === 'string' ? hashtags.split(',').map(tag => tag.trim()) : '',
         status: status
      })
      refetchFaq()
   },[question, input, hashtags, status, refetchFaq, selectedFAQ]);
   
   
   return <>
      <Card style={{maxHeight:matchSM ? 'auto' : 1000, height:matchSM ? 'auto' : 800}}>
         <CardContent>
            <Grid container item xs={12} spacing={1} style={{margin:'auto'}} fontSize={'small'} justifyItems={'center'} justifyContent={'space-between'}>
               <Grid item xs={matchSM ? 12 : 5}>
                  <Typography variant={'subtitle1'} style={{marginTop:0,marginBottom:10}}>
                  Managing Article
                  </Typography>
               </Grid>
               {!matchSM && <Grid item xs={1}></Grid>}
               <Grid item xs={matchSM ? 4 : 2}>
                  <Button variant={'outlined'}
                          size={'small'}
                          style={getButtonBorderColor(selectedFAQ?.status)}
                          onClick={handleMenuClick}
                  >
                     <Tooltip title={'Filter by status'}>
                        <>{toCapitalizeFromUnderScore(status || '')}</>
                     </Tooltip>
                  </Button>
                  
                  <Menu
                     anchorEl={anchorEl}
                     open={Boolean(anchorEl)}
                     onClose={handleClose}
                  >
                     {['all', 'draft', 'archived', 'inactive', 'published'].map((item) => (
                        <MenuItem key={item} onClick={() => handleMenuItemClick(item)}>
                           <Typography style={getButtonBorderColor(item)}>
                              {toCapitalizeFromUnderScore(item)}
                           </Typography>
                        </MenuItem>
                     ))}
                  </Menu>
               </Grid>
               {!matchSM && <Grid item xs={1}></Grid>}
               <Grid item xs={matchSM ? 4 : 2}>
                  <Button variant={'outlined'}
                          size={'small'}
                          onClick={()=>setShowingDelete(!showingDelete)}
                  >
                     <Tooltip title={'Delete Article'}>
                        <Delete/>
                     </Tooltip>
                  </Button>
               </Grid>
               {matchSM && <Grid item xs={1}></Grid>}
               <Grid item xs={matchSM ? 3 : 1}><ThumbUp fontSize={'small'} color={'primary'}/>&nbsp;{selectedFAQ?.helpful_count}&nbsp;<ThumbDown fontSize={'small'} color={'primary'}/>&nbsp;{selectedFAQ?.not_helpful_count}</Grid>
               
               <Grid container item xs={12} fontSize={'small'} style={{display:'none'}}>
                  <Grid item xs={10}></Grid>
                  <Grid item xs={1}><ThumbUp fontSize={'small'} color={'primary'}/>&nbsp;{selectedFAQ?.helpful_count}</Grid>
                  <Grid item xs={1}><ThumbDown fontSize={'small'} color={'primary'}/>&nbsp;{selectedFAQ?.not_helpful_count}</Grid>
               </Grid>
               </Grid>
            <hr/>
            <Grid container item xs={12} style={{margin:'auto'}}>
               <Grid container style={{width:'100%',margin:'auto',overflowY:'auto', maxHeight:summary ? 300 : '100vh'}}>
                  
                  {isLoadingFAQ && <CircularProgress />}
                  
                  <Grid container item xs={12}>
                     
                     <Grid item xs={12}>
                        <TextField
                           value={question}
                           onChange={(e)=>setQuestion(e.target.value)}
                           className="field"
                           key="otp"
                           id="otp"
                           label={'Enter Question'}
                           margin="normal"
                           variant="outlined"
                           fullWidth={true}
                           disabled={isLoadingFAQ || isLoadingAutoFillQuestion}
                           type={'text'}
                           InputProps={{
                              endAdornment: (
                                 <InputAdornment position="end">
                                    <Tooltip title={"Re-Generate with AI"}>
                                       <IconButton
                                          aria-label="Re-Generate with AI"
                                          onClick={() => handleAutofill('question')}
                                          size="large">
                                          {isLoadingAutoFillQuestion ? <CircularProgress /> : <FindReplace/>}
                                       </IconButton>
                                    </Tooltip>
                                 </InputAdornment>
                              ),
                           }}
                        />
                     </Grid>
                     <Grid item xs={12}>
                        <TextField
                           value={hashtags}
                           onChange={(e)=>setHashtags(e.target.value)}
                           className="field"
                           key="edit_hashtags"
                           id="edit_hashtags"
                           placeholder={'A, CSV, of, Keywords'}
                           label={'Keywords'}
                           margin="normal"
                           variant="outlined"
                           fullWidth={true}
                           disabled={isLoadingFAQ || isLoadingAutoFillHashtags}
                           type={'text'}
                           InputProps={{
                              endAdornment: (
                                 <InputAdornment position="end">
                                    <Tooltip title={"Re-Generate with AI"}>
                                       <IconButton
                                          aria-label="Re-Generate with AI"
                                          onClick={() => handleAutofill('hashtags')}
                                          size="large">
                                          {isLoadingAutoFillHashtags ? <CircularProgress /> : <FindReplace/>}
                                       </IconButton>
                                    </Tooltip>
                                 </InputAdornment>
                              ),
                           }}
                        />
                     </Grid>
                     <Grid item xs={12}>
                        <div data-color-mode={darkMode ? "light" : "light"} className={classes.editor}>
                        <MDEditor
                     ref={textareaRef}
                     visible={true}
                     height={matchSM ? 300 : 400}
                     width={'100%'}
                     value={input}
                     preview={'edit'}
                     placeholder={'Enter your answer...'}
                     
                     onChange={(value, viewUpdate) => setInput(value)}
                     theme={darkMode ? "light" : "light"}
                     previewOptions={{
                        rehypePlugins: [[rehypeSanitize]],
                     }}
                  />
                        </div>
               </Grid>
               </Grid>
               
               </Grid>
               <Grid item xs={12} style={{marginTop:50,textAlign: matchSM ? 'center' : 'right'}} justifyContent={'space-between'} alignItems={'center'}>
                  <Button
                     disabled={isLoadingFAQ || isLoadingAutoFill}
                     variant={'outlined'}
                     style={{marginBottom: matchSM ? 10 : 'inherit', marginRight:10}}
                     onClick={()=>handleAutofill('answer')}><FindReplace/>&nbsp;Auto-Fill Answer</Button>
                  <Button
                     onClick={reset}
                     variant={'outlined'}
                     style={{marginBottom: matchSM ? 10 : 'inherit', marginRight:10}}><Restore/>&nbsp;Reset</Button>
                  <Button
                     style={{marginBottom: matchSM ? 10 : 'inherit', marginRight:0}}
                     disabled={isLoadingFAQ || isLoadingAutoFill} variant={'outlined'} color={'primary'} onClick={handleSave}><Save/>&nbsp;Save</Button>
               </Grid>
            </Grid>
         </CardContent>
      </Card>
      {showingDelete && <DeleteFAQDialog handleClose={handleClose} />}
   </>;
};

export default SpecificFAQ;
