import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { minusMonth, prettyDate } from '../helpers/helpers';
import useUser from '../hooks/useUser';
import { useSnackbar } from 'notistack';
import {getLogout} from "../api";
import useCurrentUser from "../hooks/useCurrentUser";
export const UserContext = createContext({});
export const UserContextProvider = ({ children, props }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const inviteCode = urlParams.get('invite');
  const stripeStatus = urlParams.get('status');
  const stripeSessionId = urlParams.get('session_id');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  /*   const {isLoading, isRefetching, data:userData = {}, refetch: refetchUser} = useUser(stripeStatus === 'success');
   const session = userData // useMemo(()=>userData ? userData : undefined,[userData])
  const [userObject, setUser] = useState(localStorage.getItem('AUTH') ? JSON.parse(localStorage.getItem('AUTH')) : {});
  const user = useMemo(()=>Object.assign({}, userObject, userObject.user), [userData]);
  const organization = useMemo(()=>Object.assign({}, userData, userData.organization), [userData]);*/

  const { isLoading, isRefetching, data: userData = {}, refetch: refetchUser } = useUser(stripeStatus === 'success');


  const session = userData;
  const [userObject, setUser] = useState(
    localStorage.getItem('AUTH') ? JSON.parse(localStorage.getItem('AUTH')) : {},
  );
  const user = useMemo(() => {
    return { ...userObject, ...userObject.user };
  }, [userData]);
  const organization = useMemo(() => {
    return { ...userData, ...userData.organization };
  }, [userData]);

  const pageSlug = useMemo(
    () => window.location.href.substring(window.location.href.lastIndexOf('/') + 1),
    [window.location],
  );
  const onHelpPage = useMemo(() => window.location.href.includes('/help'), [window.location]);
  const [snackbarId, setSnackbarId] = useState();
  const [showingCreateContactListDialog, setShowingCreateContactListDialog] = useState(false);

  const [showOnboardRequiredDialog, setShowOnboardRequiredDialog] = useState(organization && !organization.id);
  const [showPlanRequiredDialog, setShowPlanRequiredDialog] = useState(
    organization && organization.id && !organization.plan,
  );
  const [showPhoneRequiredDialog, setShowPhoneRequiredDialog] = useState(
    Boolean(organization && organization.plan && organization.phone_numbers),
  );

  useEffect(() => {
    if (showOnboardRequiredDialog && organization && organization.id) {
      setShowOnboardRequiredDialog(false);
    }
    if (!showPlanRequiredDialog && organization && organization.id && !organization.plan) {
      setShowPlanRequiredDialog(true);
    }
    if (!showPhoneRequiredDialog && organization && organization.plan && !organization.phone_numbers) {
      setShowPhoneRequiredDialog(true);
    }
  }, [organization.id, organization.plan, organization.phone_numbers]);

  useEffect(() => {
    if (stripeStatus === 'success') {
      if (showPlanRequiredDialog) {
        //if stripe success but client doesn't see new plan, continually force refetch
        refetchUser();
      } else {
        window.location = './';
      }
    }
    if (stripeStatus === 'cancelled') {
      enqueueSnackbar('Order was cancelled!', { variant: 'error' });
    }
  }, [stripeStatus, refetchUser, showPlanRequiredDialog]);

  useEffect(() => {
    if (inviteCode) {
      localStorage.setItem('GG_INVITE', inviteCode);
    }
  }, [inviteCode]);

  useEffect(() => {
    if (userObject) {
      if (session) {
        let tempSession = userObject;
        tempSession.organization = session.organization;
        tempSession.user = session.user;
        localStorage.setItem('AUTH', JSON.stringify(tempSession));
        setUser(tempSession);
        if (snackbarId) {
          // when we're back online
          closeSnackbar(snackbarId);
          enqueueSnackbar('Back Online', {
            variant: 'success',
          });
        }
      } else {
        // todo
        // on connection loss
        /*        const snackbarId = enqueueSnackbar('No connection!', {
           variant: 'error',
           persist: true
        setSnackbarId(snackbarId)
        })*/
      }
    } else {
      //forceLogout();
    }
  }, [session]);

  const setOrganization = useCallback(
    organization => {
      let tempObject = userObject;
      //tempObject.user = tempObject.user;//.filter(item=>item.organization_id = organization.id)
      tempObject.user.organization_id = organization.id;
      tempObject.organization = organization;
      tempObject.suggested_organization = null;
      localStorage.setItem('AUTH', JSON.stringify(tempObject));
      localStorage.removeItem('GG_INVITE');
    },
    [userObject],
  );

  //universal helpers
  const [showingSearch, setShowingSearch] = useState(false);
  const [showingAIWriter, setShowingAIWriter] = useState(false);
  const [showingAIImage, setShowingAIImage] = useState(false);
  const [showingHelp, setShowingHelp] = useState(false);
  //report helpers
  const [showingRange, setShowingRange] = useState(false);
  const [startDate, setStartDate] = useState(minusMonth(new Date()));
  const [endDate, setEndDate] = useState(prettyDate(new Date()));
  
  const { token, userID } = useCurrentUser();
  const logout = useCallback(() => {
      const result = getLogout(token, userID).then(r => {
        //alert('Logged Out');
        localStorage.removeItem('AUTH');
        setUser({});
        window.location = window.location.protocol + '//' + window.location.host + '/';
      })
  }, []);

  const forceLogout = useCallback(() => {
    localStorage.removeItem('AUTH');
    setUser({});
  }, []);
  return (
    <UserContext.Provider
      value={{
        actions: {
          setUser,
          logout,
          forceLogout,
          setShowingSearch,
          setShowingHelp,
          setStartDate,
          setEndDate,
          setShowingRange,
          setOrganization,
          setShowingAIWriter,
          setShowingAIImage,
          setShowPlanRequiredDialog,
          setShowOnboardRequiredDialog,
          setShowPhoneRequiredDialog,
          setShowingCreateContactListDialog,
        },
        state: {
          showingAIWriter,
          showingAIImage,
          showingRange,
          startDate,
          endDate,
          showingSearch,
          showingHelp,
          showPlanRequiredDialog,
          showOnboardRequiredDialog,
          showPhoneRequiredDialog,
          showingCreateContactListDialog,
          ...user,
          ...organization,
          plan: organization ? organization.plan : '',
          organization_id: organization ? organization.id : '',
          inviteCode,
        },
      }}
      {...props}
    >
      {children}
    </UserContext.Provider>
  );
};
