import React, {useContext} from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import {DemoContext} from "../../../lib/contexts/DemoContext";
import {Box, Grid, Typography} from "@mui/material";
import {useParams} from "react-router";
import makeStyles from '@mui/styles/makeStyles';
import SpecificContactManagement from "./SpecificContactManagement";
import Sidebar from "./Sidebar";
const useStyles = makeStyles(theme => ({
   screen:{
      width:'100%',
      maxWidth:'80vw',
      margin:'auto',
   },
   mobileScreen:{
      width:'100%',
      maxWidth:'100%',
      margin:'auto',
   },
   container:{
      width:'100%',
      maxWidth:'80vw',
      margin:'auto',
      '& h2':{
         width:'100%',
         fontSize:20,
         marginBottom:20,
         color: theme.palette.primary.main,
         display: 'block'
      },
   },
   mobileContainer:{
      width:'100%',
      maxWidth:'100%',
      margin:'auto',
      '& h2':{
         width:'100%',
         fontSize:20,
         marginBottom:20,
         color: theme.palette.primary.main,
         display: 'block'
      },
   },
   containerTop:{
      maxHeight: '78vh',
      overflowY:"auto",
   },
}));

const ContactManagementScreen = ({management = true}) => {
   const classes = useStyles();
   const params = useParams('contactId')
   const {
      state: { matchSM, matchMD },
   } = useContext(DemoContext);
    return (
        <>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Contacts' empty />
   
           <section className={`${matchSM ? '' :'sc-feature-section-area5'} sc-pt-30 sc-md-pt-30 sc-pb-140 sc-md-pb-70`}>
              <div className="container">
                 <div className="row align-items-center">
                    <Box>
                       <Grid
                          container
                          item
                          xs={12}
                          alignItems="center"
                          spacing={0}
                          className={matchSM ? classes.mobileScreen : classes.screen}
                       >
                          <Grid
                             container
                             item xs={12}
                             direction="row"
                             justifyContent="space-between"
                             alignItems="flex-start"
                             spacing={management ? matchSM ? 2 : 2 : 0}
                             className={matchSM ? classes.mobileContainer : classes.container}
                          >
                             <Grid item xs={management ? matchMD ? 12 : 6 : 4}
                                   style={{order: matchMD ? 2 : 1}}>
                                <Sidebar management={management} />
                             </Grid>
                             <Grid item xs={management ? matchMD ? 12 : 6 : 8} style={{order: matchMD ? 1 : 2}}>
                                {params && params.contactId ? <SpecificContactManagement/> : <Typography variant={'subtitle1'} style={{marginTop: matchMD ? 0 : 145, marginBottom: matchMD ? 0 : 'inherit', textAlign:'center'}}>Select a Contact</Typography>}
                                
                             </Grid>
                          </Grid>
                       </Grid>
                    </Box>
                 </div>
                 </div>
           </section>
        </>
    );
};

export default ContactManagementScreen;
