import React, { useCallback, useContext, useMemo, useState } from 'react';
import {makeStyles} from "@mui/styles";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip, InputAdornment, CircularProgress, LinearProgress, Typography
} from "@mui/material";
import { Link, Sensors} from "@mui/icons-material";
import {DemoContext} from "../../../lib/contexts/DemoContext";
import {postAutoFAQScan} from "../../../lib/api";
import {FAQ_LIMIT_LABEL} from "../../../lib/constants/constants";

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 300,
  },
  progressBar:{
    display: 'block',
    margin:'50px auto 50px',
    backgroundColor: theme.palette.primary.backgroundColor
  },
  progressBarColor:{
    backgroundColor: theme.palette.primary.main
  },
  shareSwitch:{
    color: theme.palette.primary.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
  warning:{
    color: theme.palette.primary.main,
    margin:'10px auto 0',
    textAlign:'center',
    fontSize:14
  },
  success:{
    color: theme.palette.primary.main,
    margin:'20px auto 0',
    textAlign:'center'
  },
}));

const AutoFAQDialog = () => {
  const classes = useStyles();
  const {
    actions: { refetchFaq, enqueueSnackbar },
    state: { matchSM, matchMD, token, userID, isLoadingCurrentUser, isRefetchingCurrentUser, isAddingFaqAllowed },
  } = useContext(DemoContext);
  
  const [url, setUrl] = useState('');
  const [showingDialog, setShowingDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isScanned, setIsScanned] = useState(false);
  const [isScannedSuccessCount, setIsScannedSuccessCount] = useState(0);
  const [isScannedFailureCount, setIsScannedFailureCount] = useState(0);
  const handleClose = useCallback(()=>{
    setUrl('')
    setShowingDialog(false)
    setIsScanned(false)
    setIsLoading(false)
    setIsScannedSuccessCount(0)
    setIsScannedFailureCount(0)
  },[])
  
  const handleOpen = useCallback(async () =>{
    if (!isLoadingCurrentUser && !isRefetchingCurrentUser) {
      if (isAddingFaqAllowed) {
        setShowingDialog(true)
      }else{
        enqueueSnackbar(FAQ_LIMIT_LABEL, {variant:'error'});
        return;
      }
    }
  },[isLoadingCurrentUser, isRefetchingCurrentUser, isAddingFaqAllowed])
  
  const handleScan = useCallback(()=>{
    if(isLoading){
      return false
    }
    if(url){
      setIsScanned(false)
      setIsLoading(true)
      postAutoFAQScan(token, userID, `https://${url}`).then((resp)=>{
        console.log('resp ', resp)
        setIsLoading(false)
        setIsScanned(true)
        setIsScannedSuccessCount(resp.count)
        setIsScannedFailureCount(resp.failed_count)
        refetchFaq()
        if(resp.count > 0){
          enqueueSnackbar('Success!', {variant:'success'})
        }
      })
    }else{
      enqueueSnackbar('Please enter a valid URL.', {variant:'error'})
    }
    
  },[url, refetchFaq])
  return (
     <>
       <Button fullWidth={matchSM || matchMD} variant={'outlined'} color={'primary'} onClick={handleOpen}>
         <Tooltip title={'Scan URL'}>
           { isLoadingCurrentUser || isRefetchingCurrentUser ? <CircularProgress size={20} /> : <><Link/>&nbsp;Scan URL</> }
         </Tooltip>
       </Button>
       <Dialog
          maxWidth={'md'}
          fullWidth={true}
          open={showingDialog}
          onClose={handleClose}
          aria-labelledby="scan-url-dialog-title" className={classes.form}
       >
         <DialogTitle id="scan-url-dialog-title"><Sensors style={{marginTop:-7}}/> Auto-Add FAQ Items via URL</DialogTitle>
         <DialogContent>
           <>
             <DialogContentText>
               Enter the URL you wish to scan. We will automatically generate potential FAQs based on the content found.
               <br/>Items will be marked as 'Draft' by default, where you can review them at your convenience.
               <br/><Typography variant={'subtitle1'} className={classes.warning}>This may take up to a minute.</Typography>
             </DialogContentText>
             <form noValidate>
               <TextField
                  value={url}
                  onChange={(e)=>setUrl(e.target.value)}
                  className="field"
                  key="scan_url"
                  id="scan_url"
                  placeholder={'www.example.com/docs'}
                  label={'URL'}
                  margin="normal"
                  variant="outlined"
                  fullWidth={true}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">
                      https://
                    </InputAdornment>,
                  }}
                  disabled={isLoading}
                  type={'text'}
               />
               {isLoading && <LinearProgress style={{margin:'20px auto 0'}} />}
               {!isLoading && isScanned && <DialogContentText style={{textAlign:'center'}}>
                 <Typography variant={'subtitle1'} className={classes.success}>{isScannedSuccessCount > 0 ? 'Success!' : 'Results...'}</Typography>
                 {isScannedSuccessCount} FAQ items were generated.<br/>
                 {isScannedFailureCount} FAQ items were problematic.
               </DialogContentText>
               }
             </form>
           </>
         </DialogContent>
         <DialogActions>
           <Button onClick={handleClose}
                   className={classes.cancelButton}
                   disabled={isLoading}>
             {isScanned ? 'Close' : 'Cancel'}
           </Button>
           <Button onClick={handleScan}
                   className={classes.addButton}
                   disabled={isLoading}
           >
             Scan URL
           </Button>
         </DialogActions>
       </Dialog>
     </>
  );
}
export default AutoFAQDialog;
