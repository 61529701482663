import { useQuery } from 'react-query';
import {getOrganizationSummary} from "../api";
import useCurrentUser from "./useCurrentUser";

const useSummary = (organizationID) => {
  const { token, userID } = useCurrentUser();
  const result = useQuery('organization_summary_'+organizationID, () => getOrganizationSummary(token, userID), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useSummary;
