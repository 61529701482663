import React, {useState, useContext, useEffect} from 'react';
import {
   Box,
   Grid,
   Typography,
   TextField,
   Checkbox,
   FormControlLabel,
   FormControl,
   InputLabel,
   Select,
   MenuItem, Popover, List, ListItem, Switch, Autocomplete, ListItemText, FormHelperText,
} from '@mui/material';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import {DemoContext} from "../../../lib/contexts/DemoContext";
import {useParams} from "react-router";
import makeStyles from '@mui/styles/makeStyles';
import UploadVideoDialog from "../UploadVideoDialog/UploadVideoDialog";
import locales from "../../../lib/constants/locales";
import {ColorPicker} from "@wellbees/color-picker-input";
import {addFontFaceRule, splitByUppercase, toCamelCase} from "../../../lib/helpers/helpers";
const useStyles = makeStyles(theme => ({
   screen:{
      width:'100%',
      maxWidth:'80vw',
      margin:'auto',
   },
   mobileScreen:{
      width:'100%',
      maxWidth:'100%',
      margin:'auto',
   },
   container:{
      width:'100%',
      maxWidth:'80vw',
      margin:'auto',
      '& h2':{
         width:'100%',
         fontSize:20,
         marginBottom:20,
         color: theme.palette.primary.main,
         display: 'block'
      },
   },
   mobileContainer:{
      width:'100%',
      maxWidth:'100%',
      margin:'auto',
      '& h2':{
         width:'100%',
         fontSize:20,
         marginBottom:20,
         color: theme.palette.primary.main,
         display: 'block'
      },
   },
   containerTop:{
      maxHeight: '78vh',
      overflowY:"auto",
   },
}));

const UploadScreen = ({ management = true }) => {
   const classes = useStyles();
   const {
      state: { matchSM, matchMD },
   } = useContext(DemoContext); // Replace with your context if different
   
   const [recipient, setRecipient] = useState('');
   const [youtubeURL, setYoutubeURL] = useState('');
   const [title, setTitle] = useState('Podcast Intros');
   const [chosenFontColor, setChosenFontColor] = useState('#ffffff');
   const [chosenBackgroundColor, setChosenBackgroundColor] = useState('#000080');
   const [chosenFontFamily, setChosenFontFamily] = useState('System');
   const [addCaptions, setAddCaptions] = useState(true);
   const [addTranslations, setAddTranslations] = useState(false);
   const [addEmojis, setAddEmojis] = useState(true);
   const [removeAds, setRemoveAds] = useState(true);
   const [removeWaterMark, setRemoveWaterMark] = useState(true);
   const [originalLanguage, setOriginalLanguage] = useState(locales.find(item => item.value === 'en'));
   const [translateTo, setTranslateTo] = useState([]);
   const [outputRatio, setOutputRatio] = useState(['Original']);
   const commonRatios = {
      all: ['Original', '16/9', '4/3', '1/1', '4/5', '9/16', '2/3'],
      instagram: ['9/16', '1/1', '4/5', '16/9'],
      facebook: ['16/9', '9/16', '2/3', '4/5'],
      youtube: ['16/9', '4/3'],
      tiktok: ['9/16'],
      twitter: ['16/9', '1/1']
   };
   const availableFontColors = [
      "#000000",  // Black
      "#ffffff",  // White
      "#f0f0f0",  // Light Gray
      "#000080",  // Navy
      "#008000",  // Green
      "#800000",  // Maroon
      "#800080",  // Purple
      "#ff4500",  // Orange-Red
      "#8b0000",  // Dark Red
      "#ffa500",  // Orange
      "#ff69b4",  // Hot Pink
      "#ff1493",  // Deep Pink
      "#4b0082",  // Indigo
      "#800000",  // Dark Red
      "#ff00ff",  // Magenta
      "#9932cc",  // Dark Orchid
      "#00ced1",  // Dark Turquoise
      "#008b8b",  // Dark Cyan
      "#00ff00",  // Lime
      "#c386da",  // Light Purple
      "#ffff00"   // Yellow
   ];
   const availableBGColors = [
      "#f0f0f0",  // Light Gray
      "#333333",  // Dark Gray
      "#f5a623",  // Yellow-Orange
      "#4caf50",  // Green
      "#2196f3",  // Blue
      "#f50244",  // red
      "#e91e63",  // Pink
      "#9c27b0",  // Purple
      "#ff5722",  // Orange
      "#a5d6a7",  // Light Green
      "#81c784",  // Mint Green
      "#000080",  // Navy
      "#64b5f6",  // Sky Blue
      "#f48fb1",  // Rose
      "#ff80ab",  // Pink
      "#ce93d8",  // Lavender
      "#d1c4e9",  // Light Purple
      "#ffb74d",  // Light Orange
      "#ffcc80",  // Peach
      "#ff8a65",  // Salmon
      "#a1887f"   // Taupe
   ]
   const availableFontFamilies = [
      'System',
      'AllertaStencil',
      'AnnieUseYourTelescope',
      'Arimo',
      'Baloo',
      'BungeeShade',
      'Cabin',
      'ComingSoon',
      'FiraSans',
      'Hind',
      'Karla',
      'Lato',
      'Merriweather',
      'Montserrat',
      'NotoSans',
      'Oswald',
      'Playfair',
      'Poppins',
      'Quicksand',
      'Raleway',
      'Roboto',
      'RobotoSlab',
      'Ubuntu',
      'Underdog',
      'VastShadow',
      'Vidaloka'
   ];
   
   const handleOutputRatioChange = (ratio) => {
      setOutputRatio(prev => prev.includes(ratio) ? prev.filter(r => r !== ratio) : [...prev, ratio]);
   };
   useEffect(()=>{
      availableFontFamilies.forEach((fontFamily) => {
         const fontFilePath = `/fonts/${fontFamily}.ttf`;
         addFontFaceRule(fontFamily, fontFilePath);
      });
   },[availableFontFamilies])
   return (
        <>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Upload Video' empty />
   
           <section className={`${matchSM ? '' :'sc-feature-section-area5'} sc-pt-0 sc-md-pt-0 sc-pb-140 sc-md-pb-70`}>
                    <Box>
                       <Grid
                          container
                          item
                          xs={12}
                          alignItems="center"
                          spacing={0}
                          className={matchSM ? classes.mobileScreen : classes.screen}
                       >
                             <Grid item xs={12}>
                                   <Typography variant={'subtitle2'} style={{marginTop: matchSM ? 80 : matchMD ? 60 : 100, marginBottom: matchMD ? 0 : 'inherit', textAlign:'center'}}>Customize your Render</Typography>
                                <FormHelperText style={{margin:'auto auto 20px', textAlign:'center'}}>Our editing process takes approximately 10 minutes. You will receive a download to your Clip Collection via email.</FormHelperText>
                                <Grid
                                   container
                                   item xs={12}
                                   justifyContent="space-between">
                                <Grid
                                   item xs={5}>
                                   <TextField fullWidth label="Youtube URL" value={youtubeURL} onChange={(e) => setYoutubeURL(e.target.value)} />
                                </Grid>
                                <Grid
                                   item xs={1}>
                                   <Typography variant={'body1'} style={{display:'block',margin:'15px auto auto',textAlign:'center'}}> - OR - </Typography>
                                </Grid>
                                   <Grid
                                   item xs={5}>
                                <UploadVideoDialog/>
                             </Grid>
                             </Grid>
   
                                <FormControl fullWidth margin="normal">
                                   <TextField label="Your Email" value={recipient} onChange={(e) => setRecipient(e.target.value)} />
                                </FormControl>
   
   <>
   
                                <FormControlLabel
                                   control={
                                      <Switch checked={addCaptions} onChange={(e) => setAddCaptions(e.target.checked)} name="add_captions" />
                                   }
                                   label="Add Captions"
                                />
                                <FormControlLabel
                                   control={
                                      <Switch checked={addTranslations} onChange={(e) => setAddTranslations(e.target.checked)} name="add_translations" />
                                   }
                                   label="Add Translations"
                                />
                                <FormControlLabel
                                   control={<Switch checked={addEmojis} onChange={(e) => setAddEmojis(e.target.checked)} />}
                                   label="Add Emojis"
                                />
                                <FormControlLabel
                                   control={<Switch checked={removeAds} onChange={(e) => setRemoveAds(e.target.checked)} />}
                                   label="Remove Ads"
                                />
   </>
   
                                {addCaptions && <Grid
                                   container
                                   item xs={12}
                                   justifyContent="space-between"
                                   spacing={1}>
                                   <Grid
                                      item xs={6}>
                                      <FormControl fullWidth margin="normal">
                                         <Autocomplete
                                            id="original-language-autocomplete"
                                            options={locales}
                                            getOptionLabel={(option) => option.name}
                                            value={originalLanguage}
                                            onChange={(event, newValue) => {
                                               setOriginalLanguage(newValue);
                                            }}
                                            renderInput={(params) => (
                                               <TextField {...params} label="Original Language"/>
                                            )}
                                         />
                                      </FormControl>
                                   </Grid>
                                   <Grid
                                      item xs={6}>
                                      <FormControl fullWidth margin="normal">
                                         <Autocomplete
                                            id="font-family-autocomplete"
                                            options={availableFontFamilies.map((item)=>item)}
                                            getOptionLabel={(option) => option}
                                            value={chosenFontFamily}
                                            onChange={(event, newValue) => {
                                               setChosenFontFamily(newValue == null ? null : splitByUppercase(newValue));
                                            }}
                                            renderInput={(params) => (
                                               <TextField {...params} label="Font Family"/>
                                            )}
                                            renderOption={(props, option) => (
                                               <li {...props}>
                                                  <Typography style={{ fontFamily: option, fontSize: 40 }}>{'AaBcCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz...'}</Typography>
                                               </li>
                                            )}
                                         />
                                      </FormControl>
                                   </Grid>
                                   <Grid
                                      item xs={6}>
                                      <FormControl fullWidth margin="normal" style={{textAlign: 'center'}}>
                                         <ColorPicker value={chosenFontColor}
                                                      inputType="mui"
                                                      label={"Font Color"}
                                                      placeholder={"#FFFFFF"}
                                                      fullWidth
                                                      pickerType={'twitter'}
                                                      specialColors={availableFontColors}
                                                      onChange={(color) => setChosenFontColor(color)}/>
                                      </FormControl>
                                   </Grid>
                                   <Grid
                                      item xs={6}>
                                      <FormControl fullWidth margin="normal" style={{textAlign: 'center'}}>
                                         <ColorPicker value={chosenBackgroundColor}
                                                      inputType="mui"
                                                      label={"Background Color"}
                                                      placeholder={"#000000"}
                                                      fullWidth
                                                      pickerType={'twitter'}
                                                      specialColors={availableBGColors}
                                                      onChange={(color) => setChosenBackgroundColor(color)}/>
                                      </FormControl>
                                   </Grid>
                                </Grid>}
   
                                {addTranslations && <FormControl fullWidth margin="normal">
                                   <Autocomplete
                                      multiple
                                      id="translate-to-autocomplete"
                                      options={locales}
                                      disableCloseOnSelect
                                      getOptionLabel={(option) => option.name}
                                      value={translateTo}
                                      onChange={(event, newValues) => {
                                         setTranslateTo(newValues);
                                      }}
                                      renderOption={(props, option, {selected}) => (
                                         <li {...props}>
                                            <Checkbox
                                               checked={selected}
                                            />
                                            <ListItemText primary={option.name}/>
                                         </li>
                                      )}
                                      renderInput={(params) => (
                                         <TextField {...params} variant="outlined" label="Translate To"
                                                    placeholder="Languages"/>
                                      )}
                                   />
                                </FormControl>}
                                <div>
                                   <Typography variant="subtitle2">Output Ratios:</Typography>
                                   {commonRatios.all.map((ratio) => (
                                      <FormControlLabel
                                         control={<Checkbox checked={outputRatio.includes(ratio)} onChange={() => handleOutputRatioChange(ratio)} />}
                                         label={ratio}
                                      />
                                   ))}
                                </div>
                                <FormControlLabel
                                   control={<Checkbox checked={removeWaterMark} onChange={(e) => setRemoveWaterMark(e.target.checked)} />}
                                   label="Remove MakeClips Watermark"
                                />
                             </Grid>
                          </Grid>
                    </Box>
           </section>
        </>
    );
};

export default UploadScreen;
