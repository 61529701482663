import axios from 'axios';
import { API_HOST } from '../constants/constants';
export const getOrganizationSummary = async (token, userID) => {
  if(!token && !userID){
    return false
  }
  const apiURL = API_HOST + '/summary';
  try {
    const { data } = await axios.get(apiURL,{
      headers: {
        'Authorization': 'Bearer '+token,
        'x-gg-user-id': userID
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
