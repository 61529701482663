
export const ENVIRONMENT = [
  // always point native to production
  ['production', location.origin.includes('capacitor://localhost')],
  ['development', !location.origin.includes('capacitor://localhost') && !location.origin.includes('makeclips.ai')],
  ['staging', location.origin.includes('staging.makeclips.ai')],
  ['production', !location.origin.includes('staging.makeclips.ai') && location.origin.includes('makeclips.ai')]
].find(([name, isActive]) => isActive)[0]
export const getSubdomainClientHost = (subdomain) =>
  ({
    development: `http://${subdomain}.localhost:3001`,
    staging: `https://${subdomain}.staging.makeclips.ai`,
    production: `https://${subdomain}.makeclips.ai`
  }[ENVIRONMENT])

export const getClientHost = () =>
  ({
    development: `http://localhost:3000`,
    staging: `https://staging.makeclips.ai`,
    production: `https://makeclips.ai`
  }[ENVIRONMENT])

export const API_HOST = {
  development: 'http://localhost:5007/api/v1',
  //development: 'https://api.makeclips.ai/api/v1',
  staging: 'https://staging.api.formulatelabs.com/v1',
  production: 'https://api.makeclips.ai/api/v1'
}[ENVIRONMENT]

export const GOOGLE_ANALYTICS_TRACKING_ID = {
  development: null,
  staging: 'UA-137832774-xx',
  production: 'UA-137832774-xx'
}[ENVIRONMENT]
export const INDUSTRY_SECTORS = [
  "Accounting",
  "Airlines/Aviation",
  "Alternative Dispute Resolution",
  "Alternative Medicine",
  "Animation",
  "Apparel & Fashion",
  "Architecture & Planning",
  "Arts & Crafts",
  "Automotive",
  "Aviation & Aerospace",
  "Banking",
  "Biotechnology",
  "Broadcast Media",
  "Building Materials",
  "Business Supplies & Equipment",
  "Capital Markets",
  "Chemicals",
  "Civic & Social Organization",
  "Civil Engineering",
  "Commercial Real Estate",
  "Computer & Network Security",
  "Computer Games",
  "Computer Hardware",
  "Computer Networking",
  "Computer Software",
  "Construction",
  "Consumer Electronics",
  "Consumer Goods",
  "Consumer Services",
  "Cosmetics",
  "Dairy",
  "Defense & Space",
  "Design",
  "Education Management",
  "E-learning",
  "Electrical & Electronic Manufacturing",
  "Entertainment",
  "Environmental Services",
  "Events Services",
  "Executive Office",
  "Facilities Services",
  "Farming",
  "Financial Services",
  "Fine Art",
  "Fishery",
  "Food & Beverages",
  "Food Production",
  "Fundraising",
  "Furniture",
  "Gambling & Casinos",
  "Glass, Ceramics & Concrete",
  "Government Administration",
  "Government Relations",
  "Graphic Design",
  "Health, Wellness & Fitness",
  "Higher Education",
  "Hospital & Health Care",
  "Hospitality",
  "Human Resources",
  "Import & Export",
  "Individual & Family Services",
  "Industrial Automation",
  "Information Services",
  "Information Technology & Services",
  "Insurance",
  "International Affairs",
  "International Trade & Development",
  "Internet",
  "Investment Banking/Venture",
  "Investment Management",
  "Judiciary",
  "Law Enforcement",
  "Law Practice",
  "Legal Services",
  "Legislative Office",
  "Leisure & Travel",
  "Libraries",
  "Logistics & Supply Chain",
  "Luxury Goods & Jewelry",
  "Machinery",
  "Management Consulting",
  "Maritime",
  "Marketing & Advertising",
  "Market Research",
  "Mechanical or Industrial Engineering",
  "Media Production",
  "Medical Device",
  "Medical Practice",
  "Mental Health Care",
  "Military",
  "Mining & Metals",
  "Motion Pictures & Film",
  "Museums & Institutions",
  "Music",
  "Nanotechnology",
  "Newspapers",
  "Nonprofit Organization Management",
  "Oil & Energy",
  "Online Publishing",
  "Outsourcing/Offshoring",
  "Package/Freight Delivery",
  "Packaging & Containers",
  "Paper & Forest Products",
  "Performing Arts",
  "Pharmaceuticals",
  "Philanthropy",
  "Photography",
  "Plastics",
  "Political Organization",
  "Primary/Secondary",
  "Printing",
  "Professional Training",
  "Program Development",
  "Public Policy",
  "Public Relations",
  "Public Safety",
  "Publishing",
  "Railroad Manufacture",
  "Ranching",
  "Real Estate",
  "Recreational",
  "Facilities & Services",
  "Religious Institutions",
  "Renewables & Environment",
  "Research",
  "Restaurants",
  "Retail",
  "Security & Investigations",
  "Semiconductors",
  "Shipbuilding",
  "Sporting Goods",
  "Sports",
  "Staffing & Recruiting",
  "Supermarkets",
  "Telecommunications",
  "Textiles",
  "Think Tanks",
  "Tobacco",
  "Translation & Localization",
  "Transportation/Trucking/Railroad",
  "Utilities",
  "Venture Capital",
  "Veterinary",
  "Warehousing",
  "Wholesale",
  "Wine & Spirits",
  "Wireless",
  "Writing & Editing"
];
export const pricingData = [
  {
    "plan": "Free",
    "price": 0,
    "annual_price": 0,
    "uploadMinutes": 60,
    "otherFeatures": ["Has Watermark", "Local Upload Only", "Basic AI Curation"],
    "dev_payment_link": "https://example.com/payment/free",
    "dev_annual_payment_link": "https://example.com/payment/free/annual",
    
    "payment_link": "https://example.com/payment/free",
    "annual_payment_link": "https://example.com/payment/free/annual",
    "isVisible": true
  },
  {
    "plan": "Essential",
    "price": 19,
    "annual_price": Math.round(19 * 12 * 0.5), // 50% discount on annual
    "uploadMinutes": 220,
    "otherFeatures": ["Fast Queue", "Up to 1080p", "Basic AI Curation"],
    "dev_payment_link": "https://buy.stripe.com/test_eVag1n29H2Pc5Ta000",
    "dev_annual_payment_link": "https://buy.stripe.com/test_5kA7uRbKh3TgdlC3cd",
    
    "payment_link": "https://buy.stripe.com/4gw9CufHsgJxdeo4gg",
    "annual_payment_link": "https://buy.stripe.com/4gwbKC8f0ctha2ceUV",
    "isVisible": true
  },
  {
    "plan": "Plus",
    "price": 89,
    "annual_price": Math.round(89 * 12 * 0.5), // 50% discount on annual
    "uploadMinutes": 1100,
    "otherFeatures": ["Fast Queue", "Up to 1080p", "Advanced AI Curation", "Auto Post", "Support"],
    "dev_payment_link": "https://buy.stripe.com/test_eVag1n29H2Pc5Ta000",
    "dev_annual_payment_link": "https://buy.stripe.com/test_5kA7uRbKh3TgdlC3cd",
  
    "payment_link": "https://buy.stripe.com/3cs01U1QC50PgqA5kn",
    "annual_payment_link": "https://buy.stripe.com/4gw3e61QC78Xfmw002",
    "isVisible": true
  },
  {
    "plan": "Pro",
    "price": 179,
    "annual_price": Math.round(179 * 12 * 0.5), // 50% discount on annual
    "uploadMinutes": 2500,
    "otherFeatures": ["Priority Queue", "Up to 4K", "Custom Branding", "Enterprise Support"],
    "dev_payment_link": "https://buy.stripe.com/test_eVag1n29H2Pc5Ta000",
    "dev_annual_payment_link": "https://buy.stripe.com/test_5kA7uRbKh3TgdlC3cd",
  
    "payment_link": "https://buy.stripe.com/28o3e6brc8d1eis4gk",
    "annual_payment_link": "https://buy.stripe.com/7sI8yq3YKdxl5LW6ot",
    "isVisible": true
  },
  {
    "plan": "Enterprise",
    "price": "Contact Us",
    "annual_price": "Contact Us",
    "uploadMinutes": "Unlimited",
    "otherFeatures": ["Dedicated Support", "API Access", "Custom Solutions"],
    "dev_payment_link": "https://buy.stripe.com/test_eVag1n29H2Pc5Ta000",
    "dev_annual_payment_link": "https://buy.stripe.com/test_5kA7uRbKh3TgdlC3cd",
  
    "payment_link": "https://example.com/payment/enterprise/monthly",
    "annual_payment_link": "https://example.com/payment/enterprise/annual",
    "isVisible": false
  }
]

export const COLORS = ['#fd5c87', '#fd6f5c', '#9e9ea8', '#f6f9fc']

export const FAQ_LIMIT_LABEL = 'You have reached your plan\'s FAQ limit. Please upgrade to a plan that supports more FAQs.'
export const SOMETHING_WRONG_TRY_AGAIN_LABEL = 'Something went wrong, please try again.'
