import axios from 'axios';
import { API_HOST } from '../constants/constants';
export const updateOrganization = async (token, userID, organizationID, options) => {
  const apiURL = API_HOST + '/organizations/' + organizationID;
  
  try {
    const { data } = await axios.patch(apiURL, {organization: {...options}},{
      headers: {
      'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token,
        'x-gg-user-id': userID
    }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
