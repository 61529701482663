import { darken, lighten, adaptV4Theme, alpha } from '@mui/material/styles';
import { createTheme } from '@mui/material';
import { opacity } from './utils';

const common = {
  black: '#0b0b0c',
  white: '#ffffff',
  red: '#ff0000',
  increase: '#BEF2D7',
  decrease: '#F9CDBA',
  border: 'rgba(0,0,0,0.28)',
  divider: 'rgba(0, 0, 0, 0.12)',
  grey: {
    50: '#FAFAFC',
    100: '#F5F5F7',
    200: '#EEEEF2',
    300: '#DEDEE2',
    400: '#BDBDC2',
    500: '#9E9EA8',
    600: '#757575',
    700: '#757575',
    800: '#424247',
    900: '#212126',
  },
};
const palette = (primaryColor, secondaryColor) => {
  return {
    common: common,
    primary: {
      main: '#E16357',
      dark: darken('#E16357', 0.1),
      darker: '#e48c81',
      light: '#9E9EA8',
      text: '#555',
      contrastText: '#fff',
      background: lighten('#F1C660', 0.92),
      backgroundHover: lighten('#F1C660', 0.5),
      border: darken('#F1C660', 0.1),
      bodyText: '#111',
      paperText: '#9E9EA8',
    },
    secondary: {
      main: '#F1C660',
      dark: '#F1C660',
      light: '#f7d0b9',
      text: '#555',
      contrastText: '#fff',
      background: lighten('#F1C660', 0.92),
      backgroundHover: lighten('#F1C660', 0.5),
      border: '#F1C660',
      bodyText: '#555',
      paperText: '#EEEEF2',
    },
    branding: {
      yellow: '#F1C660',
      pink: '#DA3250FF',
    },
    backgrounds: {
      paper: '#ffffff',
      paperOpaque: '#ffffff',
      loginPaper: '#ffffff',
      default: '#fafafa',
      header: 'linear-gradient(to right, #fd6f5c, #fd5c87)',
      //header: '#FD6F5C',
      footer: '#1E1F21',
      background: '#F6F9FC', //#F5F5F7
      login: '#F7E7D4',
    },
    error: {
      main: '#DA4045',
      dark: '#A74145',
      light: '#EBB1B3',
      contrastText: '#ffffff',
      background: lighten('#DA4045', 0.92),
      backgroundHover: lighten('#DA4045', 0.5),
      border: '#A74145',
    },
    warning: {
      main: '#f8ab3a',
      dark: '#c38147',
      light: '#ffc960',
      contrastText: '#ffffff',
      background: lighten('#f8ab3a', 0.92),
      backgroundHover: lighten('#f8ab3a', 0.5),
      border: '#c38147',
    },
    success: {
      main: '#45ae57',
      dark: '#3d954c',
      light: '#60ba6f',
      contrastText: '#ffffff',
      background: lighten('#68b76b', 0.92),
      backgroundHover: lighten('#68b76b', 0.5),
      border: '#3d954c',
    },
    info: {
      main: '#536dfe',
      dark: '#25448f',
      light: '#9fa8da',
      contrastText: '#ffffff',
      background: lighten('#536dfe', 0.92),
      backgroundHover: lighten('#536dfe', 0.5),
      border: '#25448f',
    },
  };
};

const theme = (primaryColor = '#111', secondaryColor = '#777') => {
  const finalPalette = palette(primaryColor, secondaryColor);

  return createTheme(adaptV4Theme({
    themeName: 'Light',
    palette: finalPalette,
    globalClasses: {
      screen: {
        width: '96%',
        margin: '10px auto auto',
      },
      adminTableHead: {
        borderRadius: 5,
        color: finalPalette.primary.main,
        backgroundColor: finalPalette.primary.activeTab,
        '& *': {
          color: finalPalette.primary.main,
          fontWeight: 'bold',
        },
        '& .MuiTableSortLabel-active': {
          color: finalPalette.primary.dark,
        },
      },
      adminTableRow: {
        borderRadius: 5,
        color: finalPalette.primary.bodyText,
        backgroundColor: finalPalette.primary.trendChart,
        '& *': {
          color: finalPalette.primary.bodyText,
        },
        '& .MuiTableSortLabel-active': {
          color: finalPalette.primary.dark,
        },
      },
      adminTableRowWhite: {
        borderRadius: 5,
        color: finalPalette.primary.bodyText,
        backgroundColor: finalPalette.common.white,
        '& *': {
          color: finalPalette.primary.bodyText,
        },
        '& .MuiTableSortLabel-active': {
          color: finalPalette.primary.dark,
        },
      },
      patientChartCard: {
        cursor: 'pointer',
        color: finalPalette.common.grey['600'],
        padding: createTheme().spacing(1),
        width: '100%',
        height: 'auto',
        '& h1': {
          color: finalPalette.primary.main,
          fontWeight: 'bold',
          fontSize: 22,
        },
        '& h2': {
          color: finalPalette.primary.main,
          fontWeight: 'bold',
          fontSize: 14,
        },
        '& h3': {
          color: finalPalette.primary.main,
          fontWeight: 'bold',
          fontSize: 12,
        },
        '& a': {
          color: finalPalette.primary.main,
          fontWeight: 'bold',
        },
        '& textarea': {
          borderRadius: 5,
          padding: 5,
        },
      },
    },
    typography: {
      useNextVariants: true,
      htmlFontSize: 16,
      fontFamily: '"SharpBook19", "Helvetica", "Arial", sans-serif',
      fontSize: 14,
      fontWeightLight: 400,
      fontWeightRegular: 400,
      fontWeightMedium: 400,
      fontWeightBold: 400,
      color: finalPalette.primary.bodyText,
      h1: {},
      h2: {
        fontWeight: 600,
      },
      h3: {},
      h4: {},
      h5: {},
      h6: {},
      body1: {
        color: finalPalette.primary.bodyText,
        '& b': {
          color: finalPalette.primary.main,
          fontWeight: 'bold',
        },
      },
      body2: {
        color: finalPalette.primary.bodyText,
      },
      subtitle1: {
        fontWeight: 600,
        width: '100%',
        marginTop: 100,
        marginBottom: 50,
        fontSize: 24,
        color: finalPalette.primary.main,
      },
      subtitle2: {
        fontWeight: 600,
        width: '100%',
        marginTop: 20,
        marginBottom: 20,
        fontSize: 24,
        color: finalPalette.primary.main,
      },
      helper: {
        primary: finalPalette.common.grey['100'],
        secondary: opacity(finalPalette.common.grey['100'], 0.54),
        light: '#DFE9E9',
      },
    },
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: 'rgba(255,255,255, 0.7)' || finalPalette.backgrounds.paper,
          backdropFilter: 'blur(5px)',
          borderRadius: '16px',
          boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.2) !important',
        },
      },

      MuiBadge: {
        colorSecondary: {
          backgroundColor: finalPalette.primary.light + ' !important',
          color: finalPalette.common.white,
        },
      },
      MuiButton: {
        root: {},
        containedPrimary: {
          //backgroundImage: 'linear-gradient(to right, #eb4e7c, #e78a51)',
          backgroundImage: 'linear-gradient(to right, #DA3250FF, #F1C660)',
          color: finalPalette.common.white,
          padding: 6,
          transition: 'all 0.1s ease-in-out',
          '&:hover': {
            backgroundImage: 'linear-gradient(to right, #F1C660, #DA3250FF)',
            transform: 'scale(1.01)',
            //            boxShadow: '0 0 10px 5px rgba(235, 78, 124, 0.5)',
          },
          '&.Mui-disabled': {
            opacity: 0.7,
          },
        },
        outlinedPrimary: {},
        contained: {},
        outlined: {},
        text: {},
        sizeSmall: {},
        sizeLarge: {},
      },
    },
    breakpoints: {
      values: {
        xs: 320,
        sm: 769,
        md: 960,
        lg: 1080,
        xl: 1920,
      },
    },
  }));
};
export default theme;
