import axios from 'axios';
import { API_HOST } from '../constants/constants';
export const getOrganization = async (organizationId = undefined, subdomain = undefined, token, userID) => {
  if (!organizationId) {
    return false;
  }

  const apiURL = API_HOST + (organizationId ? '/organizations/' + organizationId : '/organizations');
  try {
    const { data } = await axios.get(apiURL, {
      headers: {
        Authorization: 'Bearer ' + token,
        'x-gg-user-id': userID,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
