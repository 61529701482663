import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {
   Grid,
   Typography,
   Box,
   TextField,
   Paper,
   InputAdornment,
   IconButton, Tooltip, DialogContentText, FormHelperText, CircularProgress
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {useParams} from "react-router";
import SidebarManagement from "./SidebarManagement";
import {FileCopy} from "@mui/icons-material";
import {DemoContext} from "../../lib/contexts/DemoContext";
import {getClientHost, SOMETHING_WRONG_TRY_AGAIN_LABEL} from "../../lib/constants/constants";
import {Link} from "react-router-dom";
const useStyles = makeStyles(theme => ({
  root: {
    display:'block',
    width: '100%',
    padding: 10,
    '& nav': {
      padding: '0px !important',
      margin: '0px !important',
    },
     marginBottom:10
  },
  screen:{
    width:'100%',
  //  maxWidth:'80vw',
    margin:'auto',
  },
  container:{
    width:'100%',
//    maxWidth:'80vw',
    margin:'auto auto 40px',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
  },
  containerTop:{
    maxHeight: '78vh',
    overflowY:"auto",
  },
   embedCode: {
      '& textarea':{
         fontSize:'11px !important',
      },
  }
}));

const EmbeddableChatManagement = ({management = false}) => {
  const classes = useStyles();

  const {
    actions: {refetchCurrentUser, isFeatureAllowed, enqueueSnackbar},
    state: {
      matchSM,
      matchMD,
       apiKey, chatOpen, convoMode,
      poweredBy,
      showTeam,
      showFAQ,
      hideClose,
      buttonGradient,
      autoPrompt,
      collectEmail,
      collectPhone,
      primaryColor,
      secondaryColor,
      tertiaryColor,
      quaternaryColor,
      chatPosition,
      companyStatus,
       companyTheme,
       previewMode,
      companyAIName,
      companyName,
      companyDesc,
       isLoadingCurrentUser
    },
  } = useContext(DemoContext);
   
   const slackSuccess = useMemo(()=>window.location.search.includes('slack=installed'),[])
   const slackFailed = useMemo(()=>window.location.search.includes('slack=failed'),[])
   useEffect(()=>{
      if(slackSuccess || slackFailed){
         enqueueSnackbar(slackSuccess ? 'Success! Slack Channel has been connected.' : SOMETHING_WRONG_TRY_AGAIN_LABEL, {variant: slackSuccess ? 'success' : 'error'});
      }
   },[slackSuccess, slackFailed])
  
  const inUseParams = useMemo(()=>{
     return {
        demoMode: true,
        theme: companyTheme,
        chatOpen: (previewMode === 'Open' || previewMode === 'Conversation'),
        convoMode: (previewMode === 'Conversation'),
        status:companyStatus,
        showTeam: showTeam,
        showPoweredBy: poweredBy,
        buttonGradient: buttonGradient,
        hideClose: hideClose,
        showFAQ: showFAQ,
        autoPrompt: autoPrompt,
        collectEmail: collectEmail,
        collectPhone: collectPhone,
        position: chatPosition,
        apiKey: apiKey,
        colors: [
           primaryColor || '#DA3250FF',
           secondaryColor || '#F1C660',
           tertiaryColor || '#9e9ea8',
           quaternaryColor || '#f6f9fc'
        ]
     }
  },[
     apiKey,
     previewMode,
     companyTheme,
     poweredBy,
     showTeam,
     showFAQ,
     hideClose,
     buttonGradient,
     autoPrompt,
     collectEmail,
     collectPhone,
     primaryColor,
     secondaryColor,
     tertiaryColor,
     quaternaryColor,
     chatPosition,
     companyStatus])
   
   
   const removeExistingElements = async (id) => {
      const existingElements = document.querySelectorAll(id);
      existingElements.forEach(element => element.remove());
   };
   
   const loadScript = useCallback(() => {
      return new Promise((resolve, reject) => {
         const script = document.createElement('script');
         script.id = 'sgpt_script';
         script.async = false;
         script.src = `${getClientHost()}/MakeClips.ai.js?r=${Math.random().toString(36).substring(2)}`;
         script.onload = resolve;
         script.onerror = reject;
         document.body.appendChild(script);
      });
   }, [getClientHost]);
   
   const cleanupScript = async () => {
      await removeExistingElements('#sgpt_script');
      await removeExistingElements('#sgpt_embed');
      if (window.MakeClips.ai) {
         delete window.MakeClips.ai;
      }
   };
   
   const restartScript = useCallback(async () => {
      await cleanupScript();
      if (!document.getElementById('sgpt_script') && !document.getElementById('sgpt_embed')) {
         try {
            await loadScript();
            window.MakeClips.ai(inUseParams, 'chat', 'demo');
         } catch (error) {
            console.error('Failed to load script:', error);
         }
      }
   }, [inUseParams, cleanupScript, loadScript]);
   
   useEffect( () => {
     //restartScript();
      return cleanupScript;
   }, [inUseParams, restartScript]);
   
   
  const embedCode = useMemo(()=>'<script src="https://cdn.makeclips.ai/sgpt.min.js?" onload="window.MakeClips.ai({' +
     'theme: \''+companyTheme+'\', ' +
    // '  companyStatus: '+companyStatus+',\n' +
     ' position: \''+chatPosition+'\', ' +
     ' showTeam: '+showTeam+', ' +
     ' showFAQ: '+showFAQ+', ' +
     ' autoPrompt: '+autoPrompt+', ' +
     ' collectEmail: '+collectEmail+', ' +
     ' collectPhone: '+collectPhone+', ' +
     ' buttonGradient: '+buttonGradient+', ' +
     ' apiKey: \''+apiKey+'\', ' +
     ' colors: [\''+primaryColor+'\', \''+secondaryColor+'\', \''+tertiaryColor+'\', \''+quaternaryColor+'\']' +
     '}, \'chat\')" async></script>',[
     apiKey,
     companyTheme,
    poweredBy,
    showTeam,
    showFAQ,
    hideClose,
    buttonGradient,
    autoPrompt,
    collectEmail,
    collectPhone,
    primaryColor,
    secondaryColor,
    tertiaryColor,
    quaternaryColor,
    chatPosition,
    companyStatus])
   
   const copyTextToClipboard = useCallback(async () => {
      if ('clipboard' in navigator) {
         return await navigator.clipboard.writeText(embedCode);
      } else {
         return document.execCommand('copy', true, embedCode);
      }
   },[embedCode]);
   const InternalTooltip = React.forwardRef((props, ref) => (
      <Tooltip {...props} ref={ref} />
   ));
   
   const handleCopy = () => {
      // Asynchronously call copyTextToClipboard
      copyTextToClipboard()
         .then(() => {
            enqueueSnackbar('Copied embed code.', {variant:'info'})
         })
         .catch((err) => {
            enqueueSnackbar('Your browser settings have prevented automatic copying. Please copy manually.', {variant:'error'})
            //console.log(err);
         });
   }
  return (
     <Box>
       <Grid
          container
          alignItems="center"
          spacing={0}
          className={classes.screen}
       >
         <Grid
            container
            item xs={12}
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            className={classes.container}
         >
           <Grid item xs={matchMD ? 12 : 8}>
              {!isLoadingCurrentUser ? <SidebarManagement/> : <div style={{margin:'200px auto auto', display:"block"}}><CircularProgress size={'small'}/></div>}
           </Grid>
           <Grid item xs={matchMD ? 12 : 4}>
             <Paper className={classes.root}>
               <Typography variant={'subtitle1'} style={{ marginTop: 20, marginBottom: 20 }}>
                 Install
               </Typography>
                <FormHelperText>
                   For maximum benefit, we recommend installing the app in a Slack Channel where you would like the AI's live conversations to be displayed. This is also the channel from which you can respond and assume control of the discussion.
                </FormHelperText>
    
                <a
                   onClick={(e) => {
                      if (!isFeatureAllowed('auto_forward_to_slack')) {
                         e.preventDefault();
                         enqueueSnackbar('Please upgrade to an eligible plan.', {variant:'error'})
                      }
                   }}
                   style={{ display:'block', margin:'10px auto 10px', textAlign:'center' }}
                   href={isFeatureAllowed('auto_forward_to_slack') ?
                      'https://slack.com/oauth/v2/authorize?client_id=4274529150977.5294080336129&scope=app_mentions:read,chat:write,commands,incoming-webhook,links.embed:write,links:write,chat:write.public&user_scope=&state=' +
                      apiKey : '#'
                   }
                >
                   <img
                      alt="Add to Slack"
                      height="35"
                      width="120"
                      src="https://platform.slack-edge.com/img/add_to_slack.png"
                      srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                   />
                </a>
             </Paper>
             <Paper className={classes.root}>
               <Typography variant={'subtitle1'} style={{ marginTop: 20, marginBottom: 20 }}>
                 Embed Code
               </Typography>
                <InternalTooltip title={'Please ensure the widget is placed on the same Company Website you\'ve specified.'}>
                <FormHelperText>
                   To complete installation, Copy and Paste the following snippet directly above the closing &lt;/body&gt; tag in your HTML.
                </FormHelperText>
                </InternalTooltip>
               <TextField
                  rows={11}
                  multiline={true}
                  key='embed_script'
                  id='embed_script'
                  label=''
                  value={embedCode}
                  margin='normal'
                  variant='outlined'
                  fullWidth={true}
                  type={'text'}
                  className={classes.embedCode}

                  InputProps={{
                    endAdornment: (
                       <InputAdornment position="end">
                         <InternalTooltip title={'Copy Embed Code'}>
                         <IconButton aria-label="Copy Embed Code" onClick={handleCopy} size="large">
                            <FileCopy />
                         </IconButton>
                         </InternalTooltip>
                       </InputAdornment>
                    ),
                  }}
               />
                {isFeatureAllowed('api_docs') && <FormHelperText style={{margin: 'auto', display: 'block', textAlign: 'center'}}><Link to={'../../docs'}
                                                                                                       target={'_blank'}
                                                                                                       style={{color: 'inherit'}}>API
                   Docs</Link></FormHelperText>}
             </Paper>
             
             <Box id={'demo'} className={classes.root}></Box>
             
           </Grid>
         </Grid>
       </Grid>
     </Box>
  );
};
export default EmbeddableChatManagement;
