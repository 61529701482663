import * as React from 'react';
import {
  Box, Button,
  Chip, CircularProgress, FormHelperText, Grid,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemIcon, ListItemSecondaryAction,
  ListItemText, Menu, MenuItem,
  Paper,
  TextField, Tooltip,
  Typography,
} from '@mui/material';
import {Add, Close, FilterList, Help, Star} from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';

import List from '@mui/material/List';
import { useNavigate } from 'react-router-dom';
import { useContext, useMemo, useState} from 'react';
import {toCapitalizeFromUnderScore, toUnderScoreSlug} from '../../../lib/helpers/helpers';
import { useParams } from 'react-router';
import {getButtonBorderColor} from "../../../lib/helpers/colorHelpers";
import {DemoContext} from "../../../lib/contexts/DemoContext";
import AutoFAQDialog from "../../../component/App/AutoFAQDialog/AutoFAQDialog";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    padding: 10,
    '& nav': {
      padding: '0px !important',
      margin: '0px !important',
    },
  },
  rootManagement: {
    width: '100%',
    padding: 10,
    '& nav': {
      padding: '0px !important',
      margin: '0px !important',
    },
  },
  containerTop: {
    maxHeight: '20vh',
    overflowY: 'auto',
  },
  container: {
   // maxHeight: '40vh',
    overflowY: 'auto',
  },
  chip: {
    margin: 8,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  icon: {
    color: theme.palette.primary.paperText,
  },
  button: status => {
    switch (status) {
      case 0:
        return { borderColor: '#F5AB1E' }; // Draft color
      case -2:
        return { borderColor: '#C22F2E' }; // Archived color
      case -1:
        return { borderColor: '#9C9C9C' }; // Inactive color
      case 1:
        return { borderColor: '#3DAF61' }; // Published color
      default:
        return {};
    }
  },
}));

export default function Sidebar({management}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { tag, question, faqId } = useParams();
  
  const {
    actions: { handleNewFAQ },
    state: { matchSM, matchMD, apiKey, isAddingFaqAllowed, isLoadingCurrentUser, isRefetchingCurrentUser, isLoadingFAQ, faqs  },
  } = useContext(DemoContext);
  
  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuItemClick = (item) => {
    setStatusFilter(item);
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const mostPopularHashtags = useMemo(() => getMostPopularHashtags(faqs ?? [], 100), [faqs]);
  const filteredMostPopularHashtags = useMemo(() => {
    return mostPopularHashtags?.filter(item => item?.toLowerCase().includes(search.toLowerCase())) || [];
  }, [mostPopularHashtags, search]);

  const filteredFaqs = useMemo(() => {
    return (
      faqs?.filter(
        item => statusFilter === 'all' ?
          item.question?.toLowerCase().includes(search.toLowerCase()) ||
          item.answer?.toLowerCase().includes(search.toLowerCase()) ||
          (item.hashtags && item.hashtags.some(tag => tag.toLowerCase().includes(search.toLowerCase())))
         :
           item.status === statusFilter && item.question?.toLowerCase().includes(search.toLowerCase()) ||
           item.status === statusFilter && item.answer?.toLowerCase().includes(search.toLowerCase()) ||
           item.status === statusFilter && (item.hashtags && item.hashtags.some(tag => tag.toLowerCase().includes(search.toLowerCase()))),
      ) || []
    );
  }, [faqs, search, statusFilter]);

  const handleListItemClick = (eventType, item) => {
    if (eventType === 'hashtag') {
      navigate(management ? `../dashboard/faqs/tag/${toUnderScoreSlug(item)}` : `../help/tag/${toUnderScoreSlug(item)}`, { replace: true });
    } else {
      //navigate(`../help/${item.id}/${toUnderScoreSlug(item.question)}`, { replace: true });
      navigate(management ? `../dashboard/faqs/${item.id}` : `../help/${toUnderScoreSlug(item.question)}`, { replace: true });
    }
  };
  
  const renderSecondaryText = (item) => {
    const categories = item.category ? item.category.join(', ') : null;
    const hashtags = item.hashtags && item.hashtags.filter(tag => tag !== '').length > 0 ? "#" + item.hashtags.filter(tag => tag !== '').join(', #') : null;
    
    if (categories && hashtags) {
      return `${categories} | ${hashtags}`;
    }
    return categories || hashtags;
  };
  

  return (
    <Paper className={ management ? classes.rootManagement : classes.root}>
      <TextField
        style={{ marginTop: 40, marginBottom: 0 }}
        autoFocus={true}
        fullWidth={true}
        variant="outlined"
        label="Search for..."
        placeholder=""
        value={search}
        onChange={e => setSearch(e.target.value)}
        InputProps={{
          endAdornment: search ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear search filter"
                onClick={() => setSearch('')}
                size="large">
                <Close className={classes.icon} />
              </IconButton>
            </InputAdornment>
          ) : (
            <></>
          ),
        }}
      />
  
      {!management && <>
        <Typography variant={'subtitle1'} style={{ marginTop: 40, marginBottom: 20 }}>
          Help Articles by Hashtags
        </Typography>
        <Box component="nav" aria-label="faq sidebar hashtags" className={classes.containerTop}>
          {filteredMostPopularHashtags &&
             filteredMostPopularHashtags.map(item => (
                <Chip
                   color={tag === toUnderScoreSlug(item) ? 'primary' : 'default'}
                   icon={Star}
                   label={item}
                   key={item}
                   className={classes.chip}
                   onClick={() => handleListItemClick('hashtag', item)}
                />
             ))}
        </Box>
      </>}
      
      <Typography variant={'subtitle1'} style={{ marginTop: 40, marginBottom: 0 }}>
        All Questions
        {management && <>
          <IconButton style={{float:'right'}} onClick={handleMenuClick} size="large">
            <Tooltip title={'Filter by status'}>
              <FilterList/>
            </Tooltip>
          </IconButton>
        </>}
      </Typography>
      <Menu
         anchorEl={anchorEl}
         open={Boolean(anchorEl)}
         onClose={handleClose}
      >
        {['all', 'draft', 'archived', 'inactive', 'published'].map((item) => (
           <MenuItem key={item} onClick={() => handleMenuItemClick(item)}>
             <Typography style={getButtonBorderColor(item)}>
               {toCapitalizeFromUnderScore(item)}
             </Typography>
           </MenuItem>
        ))}
      </Menu>
      <Grid container xs={12} className={classes.rootManagement} justifyItems={'center'} justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
        <Grid item xs={6} style={{margin:'auto auto 10px', textAlign:'center'}}>
          <AutoFAQDialog/></Grid>
        <Grid item xs={6} style={{margin:'auto auto 10px', textAlign:'center'}}>
          <Button fullWidth={matchSM || matchMD} disabled={!isAddingFaqAllowed} variant={'outlined'} color={'primary'} onClick={handleNewFAQ}>
            <Tooltip title={'Add Item'}>
              { isLoadingCurrentUser || isRefetchingCurrentUser ? <CircularProgress size={20} /> : <><Add/>&nbsp;New Item</> }
            </Tooltip>
          </Button>
        </Grid>
      </Grid>
      <List component="nav" aria-label="faq sidebar list" className={classes.container}  style={{
         maxHeight: matchSM ? 350 : 580}}>
        {!isLoadingFAQ && !filteredFaqs.length && <FormHelperText style={{margin: '250px auto 250px', textAlign:'center'}}>No FAQ found. Create an item to help train the AI.</FormHelperText>}
        {isLoadingFAQ && <div style={{margin: 'auto', textAlign:'center'}}><CircularProgress style={{margin: 'auto'}}/></div>}
        {filteredFaqs &&
          filteredFaqs?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)).map(item => (
            <ListItem
              key={item.id}
              button
              selected={faqId == item.id}
              onClick={() => handleListItemClick('question', item)}
            >
              {!matchSM && <ListItemIcon>
                <Help className={classes.icon}/>
              </ListItemIcon>}
              <ListItemText
                 style={{maxWidth: matchSM ? '70%' : '75%'}}
                 primary={item.question}
                 secondary={management ? renderSecondaryText(item) : ''}
              />
              {management && <ListItemSecondaryAction>
                <Button
                   variant={'outlined'}
                        size={'small'}
                        disabled={true}
                         style={matchSM ? {...{margin: '20px auto 0px', display:'block'}, ...getButtonBorderColor(item.status)} : getButtonBorderColor(item.status)}
                >{item.status}</Button>
              </ListItemSecondaryAction>}
            </ListItem>
          ))}
      </List>
    </Paper>
  );
}

function getMostPopularHashtags(data, count) {
  const hashtags = {};

  data.forEach(item => {
    item?.hashtags?.forEach(tag => {
      hashtags[tag] = (hashtags[tag] || 0) + 1;
    });
  });

  const sortedHashtags = Object.entries(hashtags).sort((a, b) => b[1] - a[1]);

  return sortedHashtags.slice(0, count).map(item => item[0]);
}
