export const hex2dec = (hex) => {
  return hex.replace('#', '').match(/.{2}/g).map(n => parseInt(n, 16));
}

export const rgb2hex = (r, g, b) => {
  r = Math.round(r);
  g = Math.round(g);
  b = Math.round(b);
  r = Math.min(r, 255);
  g = Math.min(g, 255);
  b = Math.min(b, 255);
  return '#' + [r, g, b].map(c => c.toString(16).padStart(2, '0')).join('');
}

export const rgb2cmyk = (r, g, b) => {
  let c = 1 - (r / 255);
  let m = 1 - (g / 255);
  let y = 1 - (b / 255);
  let k = Math.min(c, m, y);
  c = (c - k) / (1 - k);
  m = (m - k) / (1 - k);
  y = (y - k) / (1 - k);
  return [c, m, y, k];
}

export const cmyk2rgb = (c, m, y, k) => {
  let r = c * (1 - k) + k;
  let g = m * (1 - k) + k;
  let b = y * (1 - k) + k;
  r = (1 - r) * 255 + .5;
  g = (1 - g) * 255 + .5;
  b = (1 - b) * 255 + .5;
  return [r, g, b];
}


export const mix_cmyks = (...cmyks) => {
  let c = cmyks.map(cmyk => cmyk[0]).reduce((a, b) => a + b, 0) / cmyks.length;
  let m = cmyks.map(cmyk => cmyk[1]).reduce((a, b) => a + b, 0) / cmyks.length;
  let y = cmyks.map(cmyk => cmyk[2]).reduce((a, b) => a + b, 0) / cmyks.length;
  let k = cmyks.map(cmyk => cmyk[3]).reduce((a, b) => a + b, 0) / cmyks.length;
  return [c, m, y, k];
}

export const mix_hexes = (...hexes) => {
  let rgbs = hexes.map(hex => hex2dec(hex));
  let cmyks = rgbs.map(rgb => rgb2cmyk(...rgb));
  let mixture_cmyk = mix_cmyks(...cmyks);
  let mixture_rgb = cmyk2rgb(...mixture_cmyk);
  let mixture_hex = rgb2hex(...mixture_rgb);
  return mixture_hex;
}

export const opacity = (hex, opacity) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if(!result) {
    throw new Error('Invalid hex', hex);
  }
  return `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
    result[3],
    16,
  )}, ${opacity})`;
};

export const gradient = (type='linear', colors, background) =>
  `${type}-gradient(${colors.join(', ')}), ${background};`;
  
export const getButtonBorderColor = status => {
  switch (status) {
    case "draft":
    case 0:
      return { borderColor: '#F5AB1E', color: '#F5AB1E' };
    case "archived":
    case -2:
      return { borderColor: '#C22F2E', color: '#C22F2E'  };
    case "inactive":
    case -1:
      return { borderColor: '#9C9C9C', color: '#9C9C9C'  };
    case "published":
    case 1:
      return { borderColor: '#3DAF61', color: '#3DAF61'  };
    default:
      return {};
  }
};
