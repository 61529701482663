import { useQuery } from 'react-query';
import {getOrganizationFaqs} from "../api";
import useCurrentUser from "./useCurrentUser";

const useOrganizationFaqs = (organizationID) => {
  const { token, userID } = useCurrentUser();
  const result = useQuery('organization_faqs_'+organizationID, () => getOrganizationFaqs(organizationID, token, userID), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useOrganizationFaqs;
