import axios from 'axios';
import { API_HOST } from '../constants/constants';
export const getUser = async (localStorageUser) => {
  if (!localStorageUser) {
    return false;
  }
  const apiURL = API_HOST + ( '/session/');
  const { token, userID } = localStorageUser;
  try {
    if(!userID || !token){
      //console.log('already logged out')
      return null //{success:false, error:true, data:{}}
    }
    const { data } = await axios.get(apiURL,{
      headers: {
        'Authorization': 'Bearer '+token,
        'x-gg-user-id': userID
      }
    });
  
    if(data && data?.error){
      // we handle this in the hook above
      //console.log('logging out... getUser')
      //  localStorage.removeItem('AUTH');
    }
    return data;
  } catch (error) {
    //
      // Handle specific status codes
      if (error?.response?.status === 404 || error?.response?.status === 401) {
        localStorage.removeItem('AUTH');
      } else {
        console.error('Request failed with other:', error);
      }
  }
};
