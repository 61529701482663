import axios from 'axios';
import { API_HOST } from '../constants/constants';
export const getOrganizationFaqs = async (organizationID, token, userID) => {
  if(!token && !userID){
    return []
  }
  
  const apiURL = API_HOST + '/organization_faqs' + (organizationID ? '/'+organizationID : '');
  try {
    const { data } = await axios.get(apiURL,{
      headers: {
        'Authorization': 'Bearer '+token,
        'x-gg-user-id': userID
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
