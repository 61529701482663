import * as React from 'react';
import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Close, Help, Star } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';

import List from '@mui/material/List';
import { useNavigate } from 'react-router-dom';
import useOrganizationFaqs from '../../../lib/hooks/useOrganizationFaqs';
import {useContext, useMemo, useState} from 'react';
import { toUnderScoreSlug } from '../../../lib/helpers/helpers';
import { useParams } from 'react-router';
import {DemoContext} from "../../../lib/contexts/DemoContext";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    padding: 10,
    '& nav': {
      padding: '0px !important',
      margin: '0px !important',
    },
  },
  containerTop: {
    maxHeight: '20vh',
    overflowY: 'auto',
  },
  container: {
    maxHeight: '40vh',
    overflowY: 'auto',
  },
  chip: {
    margin: 8,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  icon: {
    color: theme.palette.primary.paperText,
  },
}));

export default function FAQItem() {
  const classes = useStyles();
  let navigate = useNavigate();
  const { tag, faqID, question } = useParams();
  const {
    state: { api_key },
  } = useContext(DemoContext);
  const {
    isLoading: isLoadingFaq,
    data: faqs,
    refetch: refetchFaq,
  } = useOrganizationFaqs(api_key);
  const [search, setSearch] = useState('');
  const mostPopularHashtags = useMemo(() => getMostPopularHashtags(faqs ?? [], 100), [faqs]);
  const filteredMostPopularHashtags = useMemo(() => {
    return mostPopularHashtags?.filter(item => item?.toLowerCase().includes(search.toLowerCase())) || [];
  }, [mostPopularHashtags, search]);

  const filteredFaqs = useMemo(() => {
    return (
      faqs?.filter(
        item =>
          item.question?.toLowerCase().includes(search.toLowerCase()) ||
          item.answer?.toLowerCase().includes(search.toLowerCase()) ||
          (item.hashtags && item.hashtags.some(tag => tag.toLowerCase().includes(search.toLowerCase()))),
      ) || []
    );
  }, [faqs, search]);

  const handleListItemClick = (eventType, item) => {
    if (eventType === 'hashtag') {
      navigate(`../help/tag/${toUnderScoreSlug(item)}`, { replace: true });
    } else {
      //navigate(`../help/${item.id}/${toUnderScoreSlug(item.question)}`, { replace: true });
      navigate(`../help/${toUnderScoreSlug(item.question)}`, { replace: true });
    }
  };

  return (
    <Paper className={classes.root}>
      <TextField
        style={{ marginTop: 40, marginBottom: 0 }}
        autoFocus={true}
        fullWidth={true}
        variant="outlined"
        label="Search for..."
        placeholder=""
        value={search}
        onChange={e => setSearch(e.target.value)}
        InputProps={{
          endAdornment: search ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear search filter"
                onClick={() => setSearch('')}
                size="large">
                <Close className={classes.icon} />
              </IconButton>
            </InputAdornment>
          ) : (
            <></>
          ),
        }}
      />

      <Typography variant={'subtitle1'} style={{ marginTop: 40, marginBottom: 20 }}>
        Help Articles by Hashtags
      </Typography>
      <Box component="nav" aria-label="faq sidebar hashtags" className={classes.containerTop}>
        {filteredMostPopularHashtags &&
          filteredMostPopularHashtags.map(item => (
            <Chip
              color={tag === toUnderScoreSlug(item) ? 'primary' : 'default'}
              icon={Star}
              label={item}
              key={item}
              className={classes.chip}
              onClick={() => handleListItemClick('hashtag', item)}
            />
          ))}
      </Box>
      <Typography variant={'subtitle1'} style={{ marginTop: 40, marginBottom: 20 }}>
        All Questions
      </Typography>
      <List component="nav" aria-label="faq sidebar list" className={classes.container}>
        {filteredFaqs &&
          filteredFaqs.map(item => (
            <ListItem
              key={item.id}
              button
              selected={question == toUnderScoreSlug(item.question)}
              onClick={() => handleListItemClick('question', item)}
            >
              <ListItemIcon>
                <Help className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary={item.question} />
            </ListItem>
          ))}
      </List>
    </Paper>
  );
}

function getMostPopularHashtags(data, count) {
  const hashtags = {};

  data.forEach(item => {
    item.hashtags.forEach(tag => {
      hashtags[tag] = (hashtags[tag] || 0) + 1;
    });
  });

  const sortedHashtags = Object.entries(hashtags).sort((a, b) => b[1] - a[1]);

  return sortedHashtags.slice(0, count).map(item => item[0]);
}
