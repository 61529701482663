import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {
   Button,
   Card,
   CardContent, CircularProgress, FormHelperText,
   Grid, Menu, MenuItem, Paper, Tooltip,
   Typography
} from "@mui/material";
import {
   GetApp,
   Email, Phone,
   Sms, Visibility, PersonAdd, Comment, Chat, Done, ThumbDown, ThumbUp, QuestionAnswer, Pageview,
} from "@mui/icons-material";
import {useParams} from "react-router-dom";
import {prettyDateTime, prettyPhone, toCapitalizeFromUnderScore, truncate} from "../../../lib/helpers/helpers";
import {makeStyles} from "@mui/styles";
import {DemoContext} from "../../../lib/contexts/DemoContext";
import {postAutoFill} from "../../../lib/api/postAutoFill";
import useContacts from "../../../lib/hooks/useContacts";
import {COLORS, SOMETHING_WRONG_TRY_AGAIN_LABEL} from "../../../lib/constants/constants";
import Avatar from 'boring-avatars';
import {postExport} from "../../../lib/api/postExport";
import useActivity from "../../../lib/hooks/useActivity";
import {
   Timeline,
   TimelineConnector, TimelineContent,
   TimelineDot,
   TimelineItem,
   TimelineOppositeContent,
   TimelineSeparator
} from "@mui/lab";

const useStyles = makeStyles(theme => ({
   root: {
      //display: 'flex',
   },
   editor:{
      borderStyle:'solid',
      borderWidth:1,
      borderRadius:5,
   },
   paper: {
      padding: '6px 16px',
   },
   secondaryTail: {
      backgroundColor: theme.palette.secondary.main,
   },
}));

const SpecificContactManagement = ({summary = false}) => {
   const classes = useStyles();
   
   const {
      state: {  darkMode, matchSM, token, userID},
      actions: {  updateFAQ, enqueueSnackbar },
   } = useContext(DemoContext);
   
   const { contactId } = useParams();
   
   const {
      isLoading,
      data: contacts,
      refetch: refetchFaq,
   } = useContacts();
   
   const selectedContact = useMemo(() => {
      return (
         contacts?.filter(
            item => item.id === contactId,
         )[0]
      );
   }, [contacts, contactId]);
   
   
   const [question, setQuestion] = useState(selectedContact?.first_name || '')
   const [hashtags, setHashtags] = useState(selectedContact?.last_name || '')
   const [input, setInput] = useState(selectedContact?.answer || '')
   const [status, setStatus] = useState(selectedContact?.status || '');
   const [isLoadingAutoFillHashtags, setIsLoadingAutoFillHashtags] = useState(false);
   const [isLoadingAutoFillQuestion, setIsLoadingAutoFillQuestion] = useState(false);
   const [isLoadingAutoFill, setIsLoadingAutoFill] = useState(false);
   const [isDownloading, setIsDownloading] = useState(false);
   const [anchorEl, setAnchorEl] = useState(null);
   const textareaRef = useRef(null);
   
   useEffect(()=>{
      reset()
   },[selectedContact])
   
   const reset = useCallback(() => {
      if(selectedContact){
         setQuestion(selectedContact.question)
         setHashtags(selectedContact.hashtags)
         setInput(selectedContact.answer)
         setStatus(selectedContact.status)
      }
   },[selectedContact]);
   const handleMenuClick = (event) => {
      setAnchorEl(event.currentTarget);
   };
   const handleMenuItemClick = (item) => {
      //'this_contact', 'all_contacts'
      setStatus(item)
      setAnchorEl(null);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };
   
   const stateMap = {
      hashtags: [setHashtags, setIsLoadingAutoFillHashtags],
      question: [setQuestion, setIsLoadingAutoFillQuestion],
      answer: [setInput, setIsLoadingAutoFill],
   };
   const handleAutofill = useCallback(async (event_type) => {
      const [setStateValue, setIsLoading] = stateMap[event_type] || [setInput, setIsLoadingAutoFill];
      setIsLoading(true)
      try {
         const details = {
            keywords:hashtags,
            question:question,
            answer:input,
         }
         const result = await postAutoFill(event_type, details);
         if(result && result.autofill){
            try {
               // Attempt to parse the JSON. If it's not JSON, an error will be thrown
               const parsed = JSON.parse(result.autofill);
               setStateValue(parsed);
            } catch (error) {
               // If it's a regular string, just set the state to the string itself
               setStateValue(result.autofill);
            }
         }else{
            enqueueSnackbar('Something went wrong!', {variant:'info'})
         }
      } catch (error) {
         console.error(error);
      } finally {
         setIsLoading(false);
      }
   }, [hashtags, question, input, setHashtags, setQuestion, setInput]);
   
   const handleSave = useCallback(() => {
      updateFAQ(selectedContact.id, {
         question: question,
         answer: input,
         hashtags: hashtags,
         status: status
      })
   },[question, input, hashtags, status]);
   
   
   const handleFileExport = useCallback((type, format) => {
      if(type && format){
         setIsDownloading(type);
         postExport(token, userID,type, format).then((result)=>{
            if(result.status === 200){
               const data = result.data
               const prefix = 'SGPT_Export_';
               const fileName = prefix+type + "." + format;
               const blob = new Blob([format === 'csv' ? data : JSON.stringify(data)], { type: format === 'csv' ? 'text/csv' : 'text/json' })
               // Create an anchor element and dispatch a click event on it to trigger a download
               const a = document.createElement('a')
               a.download = fileName
               a.href = window.URL.createObjectURL(blob)
               const clickEvt = new MouseEvent('click', {
                  view: window,
                  bubbles: true,
                  cancelable: true,
               })
               a.dispatchEvent(clickEvt)
               a.remove()
            }else{
               enqueueSnackbar(SOMETHING_WRONG_TRY_AGAIN_LABEL, {variant:'error'})
            }
            setIsDownloading(null);
            setAnchorEl(null);
         }).catch(()=>{
            enqueueSnackbar(SOMETHING_WRONG_TRY_AGAIN_LABEL, {variant:'error'})
            setIsDownloading(null);
            setAnchorEl(null);
         })
      }
   },[token, userID])
   
   
   const { isLoading:activityLoading, data:rawActivityData, refetch:activityRefetch } = useActivity(selectedContact?.id);
   const activityData = useMemo(()=>rawActivityData && !rawActivityData.error ? rawActivityData.filter(item=>item.event_type) : [],[rawActivityData]);
   return (
        <>
           <Card>
              <CardContent>
                 <Grid container item xs={12} spacing={1} style={{margin:'auto'}}>
                    <Grid item xs={matchSM ? 12 : 10}>
                       <Typography variant={'subtitle1'} style={{marginTop:0,marginBottom:0}}>
                       Viewing Contact
                       </Typography>
                    </Grid>
                    <Grid item xs={matchSM ? 12 : 2}>
                       <Button variant={'outlined'}
                               size={'small'}
                               onClick={handleMenuClick}
                               color={'primary'}
                               style={{float:'right'}}
                       >
                          <Tooltip title={'Export Contact'}>
                             <><GetApp/>&nbsp;Export</>
                          </Tooltip>
                       </Button>
   
                       <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                       >
                             <MenuItem key={'ical'}>
                                <DownloadICalContact contact={selectedContact} />
                             </MenuItem>
                             <MenuItem key={'all_contacts'} onClick={() => handleFileExport("users", "csv")}>
                                Download All Contacts
                             </MenuItem>
                       </Menu>
                    
                    
                    </Grid>
                 </Grid>
                 <hr/>
                 <Grid container item xs={12} style={{margin:'auto'}}>
                    <Grid container style={{width:'100%',margin:'auto',overflowY:'auto', maxHeight:summary ? 300 : '100vh'}}>
                       
                       {isLoading && <CircularProgress />}
   
                       <Grid container item xs={12}>
                          <Grid item xs={2}>
                             <Avatar
                                className="avatar"
                                size={45}
                                name={
                                   selectedContact?.id || selectedContact?.phone_number || selectedContact?.email || ''
                                }
                                variant={'beam'}
                                colors={COLORS}
                             />
                          </Grid>
                          <Grid item xs={10}>
                             <Typography variant={'body1'} color={'textPrimary'}>{selectedContact?.first_name || ''} {selectedContact?.last_name || ''}</Typography>
                             <Typography variant={'body1'} color={'textSecondary'}>{selectedContact?.email || ''}</Typography>
                             <Typography variant={'body1'} color={'textSecondary'}>{selectedContact?.phone_number ? prettyPhone(selectedContact.phone_number) : ''}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                          </Grid>
                    </Grid>
   
                       <Grid item xs={12}>
                          <Typography variant={'subtitle1'} style={{marginTop:20,marginBottom:20}}>
                             Activity
                          </Typography>
                       </Grid>
                       <div style={{ width: '100%', margin: 'auto', overflowY: 'auto', maxHeight: matchSM ? 250 : 675 }}>
                          <Timeline align="alternate">
                             {activityData.map((logItem, index) => (
                                <TimelineItem key={logItem.id}>
                                   <TimelineOppositeContent>
                                      <Typography variant="body2" color="textSecondary">
                                         {prettyDateTime(logItem.created_at)}
                                      </Typography>
                                   </TimelineOppositeContent>
                                   <TimelineSeparator>
                                      <TimelineDot color={index % 2 === 0 ? 'primary' : 'secondary'}>
                                         {getIcon(logItem.event_type,logItem.event_value)}
                                      </TimelineDot>
                                      <TimelineConnector />
                                   </TimelineSeparator>
                                   <TimelineContent>
                                      <Paper elevation={3} className={classes.paper}>
                                         <Typography variant="body1">
                                            {getPrettyTerm(logItem.event_type)}
                                         </Typography>
                                         <Tooltip title={logItem.event_value || ''}>
                                         <Typography variant="body2" color={'textSecondary'}>{truncate(toCapitalizeFromUnderScore(logItem.event_value || ''), 50)}</Typography>
                                         </Tooltip>
                                      </Paper>
                                   </TimelineContent>
                                </TimelineItem>
                             ))}
                          </Timeline>
                          {activityData && !activityData.length && <FormHelperText style={{margin: 'auto'}}>None Found</FormHelperText>}
                       </div>
                       
                    </Grid>
                    <Grid item xs={12} style={{marginTop:50,textAlign:'right'}}>
   
                       <Button variant={'outlined'} color={'primary'}
                               size={'small'}
                               style={{marginRight:10}}
                               onClick={()=>window.open(`mailto:${selectedContact?.email}`, '_self')}
                               disabled={!selectedContact?.email}
                       >
                             <Email/>&nbsp;Email
                       </Button>
                       <Button variant={'outlined'} color={'primary'}
                               size={'small'}
                               style={{marginRight:10}}
                               onClick={()=>window.open(`sms:${selectedContact?.phone_number}`, '_self')}
                               disabled={!selectedContact?.phone_number}
                       >
                             <Sms/>&nbsp;Text
                       </Button>
                       <Button variant={'outlined'} color={'primary'}
                               size={'small'}
                               onClick={()=>window.open(`tel:${selectedContact?.phone_number}`, '_self')}
                               disabled={!selectedContact?.phone_number}
                       >
                             <Phone/>&nbsp;Call
                       </Button>
                    </Grid>
                 </Grid>
              </CardContent>
           </Card>
        </>
    );
};

export default SpecificContactManagement;
const getDotColor = (eventType) => {
   switch (eventType) {
      case 'page_visit':
         return 'primary';
      case 'Contact Creation':
         return 'secondary';
      case 'chat':
         return 'primary';
      case 'faq_vote':
         return 'secondary';
      case 'Event Type 2':
         return 'error';
      default:
         return 'default';
   }
};

// Define a helper function to get the MUI-v4 icon component based on event_type
const getIcon = (eventType, eventValue = null) => {
   switch (eventType) {
      case 'page_visit':
         return <Visibility />;
      case 'contact_created':
         return <PersonAdd />;
      case 'chat_message':
         return <Comment />;
      case 'ai_response':
         return <Chat />;
      case 'agent_response':
         return <QuestionAnswer />;
      case 'faq_view':
         return <Pageview />;
      case 'faq_vote':
         return eventValue == 'up' ? <ThumbUp /> : <ThumbDown/>;
      default:
         return <Done />;
   }
};
// Define a helper function to get the MUI-v4 icon component based on event_type
const getPrettyTerm = (eventType, eventValue = null) => {
   switch (eventType) {
      case 'ai_response':
         return 'AI Responded';
      case 'agent_response':
         return 'Agent Responded';
      case 'faq_vote':
         return 'FAQ Vote';
      default:
         return toCapitalizeFromUnderScore(eventType);
   }
};
function generateICalContact(contact) {
   const vcard = `BEGIN:VCARD
VERSION:3.0
FN:${contact.first_name} ${contact.last_name}
TEL;TYPE=CELL:${contact.phone_number}
EMAIL:${contact.email}

END:VCARD`;
   
   const blob = new Blob([vcard], { type: 'text/vcard' });
   const url = URL.createObjectURL(blob);
   
   return url;
}

function DownloadICalContact({ contact }) {
   const downloadUrl = generateICalContact(contact);
   return (
      <div>
         <a href={downloadUrl} download={`${contact.first_name}_${contact.last_name}.vcf`} style={{color:'inherit'}}>
            Download {contact.first_name || 'Contact'} {contact.last_name}
         </a>
      </div>
   );
}
